import clsx from "clsx"
import React, { useEffect, useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import { connect } from "react-redux"
import YouTube from "react-youtube"
import { isEmpty } from "utils"
import { getErrorMessage } from "utils/getErrorMessage"
import { actionCreator } from "../../actions/actionCreator"
import { operation } from "../../actions/operation"
import logo from "../../assets/images/logo-main.svg"
import classes from "./auth.module.scss"
const debug = require("debug")("Auth")


/** @type {import("react-youtube").Options} */
const youtubeConfig = {
  playerVars: {
    autoplay: 1, // Auto-play the video on load
    disablekb: 1,
    mute:1,
    controls: 0, // Hide pause/play buttons in player
    showinfo: 0, // Hide the video title | deprecated
    modestbranding: 1, // Hide the Youtube Logo
    // loop: 1, // Run the video in a loop
    fs: 0, // Hide the full screen button
    autohide: 0, // Hide video controls when playing
    rel: 0,
    // playlist: "6my2ltOHaZE",
    // playlist: "",
  },
}

function SubmitError({ message, control }) {
  const { username, password } = useWatch({ control })
  useEffect(() => {
    // control.clearErrors()
    if (!isEmpty(control.errorsRef.current)) {
      control.errorsRef.current = {}
      control.reRender()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, password])
  if (!message) return null
  return <div className={classes.ErrorMessage}>{message}</div>
}

function BaseAuth({ onSubmit, defaultValues }) {
  const { reset, register, errors, handleSubmit, setError, control } = useForm({ defaultValues })
  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])
  // debug("errors", errors)
  return (
    <form
      className={clsx(classes.Form, !isEmpty(errors) && classes.FormError)}
      onSubmit={handleSubmit((data) =>
        onSubmit(data).catch((error) => {
          const message = getErrorMessage(error)
          debug("error submit", message)
          setError("submit", { type: "manual", message })
        })
      )}
    >
      <input
        className={classes.Input}
        type="text"
        required
        autoComplete="username"
        // readOnly
        // onFocus={(evt) => evt.target.removeAttribute('readonly')}
        placeholder="Введите логин"
        name="username"
        ref={register}
      />
      <input
        className={classes.Input}
        type="password"
        required
        autoComplete="current-password"
        placeholder="Введите пароль"
        name="password"
        ref={register}
      />
      <SubmitError message={errors.submit?.message} control={control} />
      <button className={classes.Button} disabled={errors.username || errors.password}>
        Войти
      </button>
    </form>
  )
}

export const Auth = ({ login, setShowPreloaderPage, isMobile }) => {
  const [showVideo, setShowVideo] = useState(false)

  // const videoRef = useRef()
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     void videoRef.current?.internalPlayer?.playVideo()
  //   }, 1000)
  //   return () => clearInterval(interval)
  // }, [])

  return (
    <>
      {!isMobile && (
        <YouTube
          // ref={videoRef}
          videoId="z1_X86D34Q0"
          containerClassName={clsx(classes.VideoBGContainer, showVideo || classes.Hidden)}
          className={classes.VideoBG}
          opts={youtubeConfig}
          onEnd={(ev) => debug("videoEnded", ev.target.seekTo(0))}
          onPlay={() => {
            debug("video ready")
            setShowVideo(() => true)
          }}
        />
      )}
      <section className={classes.Wrapper}>
        <div className={classes.FormAuth}>
        <h1 className="visually-hidden">Форма входа</h1>
        <img className={classes.Logo} src={logo} alt="Логотип Dream Team" width="130" height="66" />
        <div className={classes.TittleAuth}>Вход в Dream Team</div>
        <BaseAuth
          onSubmit={(data) => {
            setShowPreloaderPage(true)
            // debug("submitting data", data)
            return login(data).catch((err) => {
              setShowPreloaderPage(false)
              throw err
            })
          }}
        />
        </div>
      </section>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    login(data) {
      return dispatch(operation.login(data))
    },
    setShowPreloaderPage(payload) {
      dispatch(actionCreator.setShowPreloaderPage(payload))
    },
  }
}

export default connect(null, mapDispatchToProps)(Auth)
