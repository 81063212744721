import clsx from "clsx"
import React, { useCallback } from "react"
import { NavLink, Redirect, Route, useHistory } from "react-router-dom"
import classes from "./tabs.module.scss"

/** @typedef {import("./types").TabProps} TabProps */

/** @param {{list: import("./tabs").TabProps[]}} props */
export function Tabs({ list, basePath }) {
  return (
    <div className={classes.Tabs}>
      {list.map(({ path, title }) => (
        <NavLink key={path} to={`${basePath}/${path}`} className={classes.Tab} activeClassName={classes.Active}>
          {title}
        </NavLink>
      ))}
    </div>
  )
}

/** @param {{list: import("./tabs").TabProps[]}} props */
export function TabsDumb({ list, onClick, active, className, ...props }) {
  return (
    <div className={clsx(classes.Tabs, className)} {...props}>
      {list.map(({ path, title }) => (
        <button
          key={path}
          onClick={() => onClick(path)}
          // eslint-disable-next-line eqeqeq
          className={clsx(classes.Tab, path == active && classes.Active)}
        >
          {title}
        </button>
      ))}
    </div>
  )
}

/**
 * @param {{list: TabProps[], basePath: string, defaultTab: string, className?: string}} props
 */
export function TabsRouter({ list, basePath, defaultTab, ...props }) {
  const history = useHistory()
  const handleClick = useCallback(
    (path) => {
      history.push(basePath + "/" + path)
    },
    [basePath, history]
  )
  return (
    /** TODO: decide how to do floating routes that define a param */
    <Route
      path={basePath + "/:tab?"}
      render={({ match }) => {
        const tab = match.params.tab
        // eslint-disable-next-line eqeqeq
        if (tab && list.find((t) => t.path == tab)) {
          return <TabsDumb onClick={handleClick} list={list} active={match.params.tab} {...props} />
        } else {
          return <Redirect to={basePath + "/" + defaultTab} />
        }
      }}
    />
  )
}

export const ToolbarTabsClass = classes.ToolbarTabs
