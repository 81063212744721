import clsx from "clsx"
import React, { useMemo } from "react"
import AutoSizer from "react-virtualized-auto-sizer"
import { FixedSizeList } from "react-window"
import { imagePath } from "../../../utils/api"
import { Box } from "../box/box"
import { DC } from "../dc/dc"
import { Empty } from "../empty/Empty"
import { ScrollResizer, ScrollWrap, useScroll, useVirtualizedScroll } from "../scroll/scroll"
import classes from "./buy-history.module.scss"
/** @typedef {import("../../../swagger-types").definitions["Покупка"]} HistoryEntry */

/** @param {{list:HistoryEntry[]}} */
export function BuyHistory({ list = [], buyHistory = [], className, Row = BuyRow, ...props }) {
  const us = useScroll()
  const vs = useVirtualizedScroll(us.updateScrollState)

  const data = useMemo(() => {
    return {
      list,
      buyHistory,
    }
  }, [list, buyHistory])
  return (
    <Box className={clsx(classes.Wrap, className)} {...props}>
      <ul className={classes.List}>
        {list.length === 0 && <Empty>Нет покупок</Empty>}
        <ScrollWrap us={us}>
          <AutoSizer>
            {({ height, width }) => (
              <>
                <FixedSizeList height={height} itemSize={34} itemData={data} itemCount={list.length} width={width} itemKey={itemKey} {...vs}>
                  {Row}
                </FixedSizeList>
                <ScrollResizer height={height} length={list.length} update={vs.onScroll} />
              </>
            )}
          </AutoSizer>
        </ScrollWrap>
      </ul>
    </Box>
  )
}

function itemKey(index, _data) {
  /* TODO: remove i from key on real data */
  // key={(`${e.itemID}${e.buyTimestamp}`, i)}

  const key = index
  return key
}

function BuyRow({ style, data, index: i }) {
  const e = data.list[i]

  return (
    <li style={style}>
      <div className={classes.HistoryItem}>
        <DC count={Math.abs(e.amount)} className={classes.HistoryItemAmount} />
        <img src={imagePath + "shop/" + e.itemImage} alt="" className={classes.HistoryItemImg} />
        <div className={classes.SmallTitle}>{e.itemTitle}</div>
        <div className={classes.HistoryItemDate}>{new Date(e.buyTimestamp).toLocaleDateString()}</div>
      </div>
    </li>
  )
}

export function OperationHistory({ list = [], buyHistory = [], ...props }) {
  function formatOperation(op) {
    switch (op.operation) {
      case "PURCHASEITEM":
        return "Покупка: " + buyHistory.find((b) => b.itemID === op.objectID)?.itemTitle
      case "BATTLEWIN":
        return "Победа в батле"
      case "BATTLELOSE":
        return "Поражение в батле"
      case "ADM_BONUS":
        if (op.note) {
          return op.note.slice(0, 1).toUpperCase() + op.note.slice(1)
        } else {
          return op.operation
        }
      case "AUTORATING":
        return "Начисление за рейтинг"
      case "RESERVE":
      case "BADGE_ACHIEVEMENT":
      case "LEVEL_ACHIEVEMENT":
      case "OFFICE_TO_USER":
      case "USER_TO_OFFICE":
      case "OFFICE_TO_OFFICE":
      default:
        return op.operation
    }
  }

  return (
    <Box withScroll {...props}>
      {list.length === 0 && <Empty>Нет операций</Empty>}
      {list.map((op) => (
        <div key={`${op.operationID}${op.operationDate}`} className={classes.HistoryItem}>
          <DC count={op.amount} className={classes.HistoryItemAmount} />
          <div>{formatOperation(op)}</div>
          <div className={classes.HistoryItemDate}>{new Date(op.operationDate).toLocaleDateString()}</div>
        </div>
      ))}
    </Box>
  )
}

export function OperationRow({ style, data, index: i }) {
  const op = data.list[i]
  const buyHistory = data.buyHistory
  function formatOperation(op) {
    switch (op.operation) {
      case "PURCHASEITEM":
        return "Покупка: " + buyHistory.find((b) => b.itemID === op.objectID)?.itemTitle
      case "BATTLEWIN":
        return "Победа в батле"
      case "BATTLELOSE":
        return "Поражение в батле"
      case "ADM_BONUS":
        if (op.note) {
          return op.note.slice(0, 1).toUpperCase() + op.note.slice(1)
        } else {
          return op.operation
        }
      case "AUTORATING":
        return "Начисление за рейтинг"
      case "RESERVE":
      case "BADGE_ACHIEVEMENT":
      case "LEVEL_ACHIEVEMENT":
      case "OFFICE_TO_USER":
      case "USER_TO_OFFICE":
      case "OFFICE_TO_OFFICE":
      default:
        return op.operation
    }
  }
  return (
    <li style={style}>
      <div key={`${op.operationID}${op.operationDate}`} className={classes.HistoryItem}>
        <DC count={op.amount} className={classes.HistoryItemAmount} />
        <div className={classes.SmallTitle}>{formatOperation(op)}</div>
        <div className={classes.HistoryItemDate}>{new Date(op.operationDate).toLocaleDateString()}</div>
      </div>
    </li>
  )
}
