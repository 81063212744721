import clsx from "clsx"
import React from "react"
import { formatAmount } from "../../../utils"
import classes from "./dc.module.scss"

export function DC({ count, className = "", ...rest }) {
  return (
    <div  className={clsx(classes.Price, className)} {...rest}>
      <span><span className={classes.count}>{formatAmount(count)+" "}</span><span className={classes.text}> DC</span></span>
    </div>
  )
}
