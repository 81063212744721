import React, { useCallback } from "react"
import SelectMe from "react-select-me"
import "./select.scss"

export function Select({ onChange, value, options, ...props }) {
  const handleChange = useCallback(
    (o) => {
      onChange(o.value)
    },
    [onChange]
  )
  return <SelectMe className="hops" placeholder="Выберите..." value={value} options={options} onChange={handleChange} {...props} />
}
